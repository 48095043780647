import type { RMDXOpts as Opts, RDMDProps as Props, RMDXProps } from '@readme/iso';
import type { MDXModule } from 'mdx/types';

import * as rmdx from '@readme/mdx';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import rdmdComponentOverrides from '@core/utils/rdmdComponentOverrides';

import './style.scss';

type RunResults = Awaited<ReturnType<typeof rmdx.run>>;

export async function exec(body: string, opts: Opts, cb: (module: RunResults) => void) {
  const customBlocksCompiled = {};
  const customBlocks = {};

  const promises = Object.entries(opts.components || {}).map(async ([tag, source]): Promise<[string, MDXModule]> => {
    customBlocksCompiled[tag] = rmdx.compile(source, { ...opts, components: {} });
    const mod = await rmdx.run(customBlocksCompiled[tag], { ...opts, components: {} });

    return [tag, mod];
  });

  (await Promise.all(promises)).forEach(([tag, node]) => {
    customBlocks[tag] = node;
  });

  const components = { ...rdmdComponentOverrides, ...customBlocks };
  const vfile = rmdx.compile(body, { ...opts, components: customBlocksCompiled });
  const module = await rmdx.run(vfile, { ...opts, components });

  cb(module);

  return module;
}

function useRMDX(body: string, _, optsParam: Opts) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const opts = useMemo(() => optsParam, [JSON.stringify(optsParam)]);
  const [Content, setContent] = useState<RunResults>();
  const isMounted = useRef<boolean>(true);
  const renderCount = useRef<number>(0);

  useEffect(() => {
    isMounted.current = true;
    renderCount.current += 1;
    const id = renderCount.current;

    try {
      exec(body, opts, content => {
        if (!isMounted.current) return;

        // Only set content if the execution is for the latest render to avoid
        // re-renders from stale executions.
        if (id !== renderCount.current) return;

        setContent(() => content);
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return () => {
      isMounted.current = false;
    };
  }, [body, opts]);

  return Content;
}

export const TOC = ({ body, children, dehydrated, opts = {} }: Props) => {
  const Content = useRMDX((body || children) as string, true, opts);

  return Content ? <Content.Toc /> : <div dangerouslySetInnerHTML={{ __html: dehydrated || '' }} />;
};

const RMDX = ({
  body,
  children = body,
  className,
  dehydrated,
  excerpt,
  opts = {},
  skipBaseClassName = false,
  Tag = 'div',
  ...rest
}: RMDXProps) => {
  const doc: string = excerpt
    ? rmdx.mdx({ type: 'root', children: [rmdx.mdast(children).children[0]] })
    : (children as string);
  const Content = useRMDX(doc, false, opts);
  const classes = [skipBaseClassName !== true && 'markdown-body', className || ''];
  const props = { ...rest, className: `rm-Markdown ${classes.filter(c => c).join(' ')}`, 'data-testid': 'RDMD' };

  if (!Content) {
    return (
      <Tag {...props} dangerouslySetInnerHTML={{ __html: (dehydrated || '').replace(/^<span>(.*)<\/span>$/s, '$1') }} />
    );
  }

  return (
    <Tag {...props}>
      <Content.default />
    </Tag>
  );
};

export default RMDX;
